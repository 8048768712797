import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {SelectBox, TextArea, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";
import LDPH from "../helpers/LeopardDropdownHelper";
import {RequiredRule, Validator} from "devextreme-react/validator";

class LeopardRealtimeMessagingPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            definitionParameters: null
        };
        this.uiObjectInstances = [];
    }

    componentDidMount() {
        if (!LDH.IsObjectNull(this.props.definitionParameters)) {
            let data = LDH.DeepClone(this.props.definitionParameters);
            for (let i = 0; i < data.length; i++) {
                if (LDH.IsObjectNull(data[i].minSearchLength) ||
                    LDH.IsValueEmpty(data[i].minSearchLength)) {
                    data[i].minSearchLength = 1;
                }
                if (LDH.IsObjectNull(data[i].autoCompleteOperation) ||
                    LDH.IsValueEmpty(data[i].autoCompleteOperation)) {
                    data[i].autoCompleteOperation = "contains";
                }
                if (LDH.IsObjectNull(data[i].allowTyping) ||
                    LDH.IsValueEmpty(data[i].allowTyping)) {
                    data[i].allowTyping = true;
                }
            }
            this.setState({
                definitionParameters: data
            });
        } else {
            this.setState({
                definitionParameters: []
            });
        }
    };

    componentWillUnmount = () => {
        let uiObjectInstances = this.uiObjectInstances;
        LRH.DisposeUIInstancesFromList(uiObjectInstances);
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null ||
            data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstances;
        instances[data.input] = data.e.instance;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstances;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    updateValueForParameters = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        for (let i = 0; i < clonedParameters.length; i++) {
            let currentColumn = clonedParameters[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                currentColumn[pName] = data.e.value;
            }
        }
        this.setState({definitionParameters: clonedParameters}, function () {
            that.props.definitionRealtimeMessagesOnChanged(that.state);
        });
    };

    parameterDeleteOnClick = (data) => {
        let that = this;
        let clonedParameters = LDH.DeepClone(this.state.definitionParameters);
        let filteredParameters = [];
        for (let i = 0; i < clonedParameters.length; i++) {
            if (clonedParameters[i].id !== data.id) {
                filteredParameters.push(clonedParameters[i]);
            }
        }
        let keys = Object.keys(this.uiObjectInstances);
        for (let j = 0; j < keys.length; j++) {
            if (keys[j].startsWith("parameters_" + this.props.dataViewId +
                "_uiControl_" + data.index)) {
                delete this.uiObjectInstances[keys[j]];
            }
        }
        this.setState({definitionParameters: filteredParameters}, function () {
            that.props.definitionRealtimeMessagesOnChanged(that.state);
        });
    };

    parametersAddOnClick = () => {
        let that = this;
        this.setState({
            definitionParameters: [
                ...this.state.definitionParameters, {
                    id: LDH.GenerateGuid(),
                    autoCompleteOperation: "contains",
                    minSearchLength: 1,
                    displayOrder: 0,
                    controlWidth: 120,
                    allowTyping: true
                }
            ]
        }, function () {
            that.props.definitionRealtimeMessagesOnChanged(that.state);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    render() {
        let that = this;
        if (LDH.IsObjectNull(this.state.definitionParameters)) return null;

        let result = this.state.definitionParameters.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.definitionParameters[i].parameterName)
                                ? "Parameter " + (i + 1)
                                : that.state.definitionParameters[i].parameterName}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.parameterDeleteOnClick({
                                   e, id: that.state.definitionParameters[i].id, index: i
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"parameterEditorParameterName_" + i + "_help"}
                                                        title={"Parameter name"}
                                                        text={"Give a name for the parameter."}/>
                                <span>Parameter name:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].parameterName}
                                         maxLength={20}
                                         ref={(e) => that.setInputFieldInstance({
                                             e,
                                             input: "parameters_" + that.props.dataViewId +
                                                 "_uiControl_" + i +
                                                 "_parameterEditorParameterName"
                                         })}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "parameterName", item: item, index: i
                                         })}>
                                    <Validator>
                                        <RequiredRule
                                            validationCallback={
                                                (e) => that.customValidationRuleCallback({
                                                    e,
                                                    input: "parameters_" + that.props.dataViewId +
                                                        "_uiControl_" + i +
                                                        "_parameterEditorParameterName",
                                                    rules: [{rule: "required"}]
                                                })
                                            } type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorMessagingType_" + i + "_help"}
                                    title={"Messaging type"}
                                    text={"Specify a type for the messaging."}/>
                                <span>Messaging type:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionWebsocketConnectionMethod}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorMessagingType"
                                           })}
                                           value={that.state.definitionParameters[i].messagingType}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "messagingType", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorEventIdentifier_" + i + "_help"}
                                    title={"Event identifier"}
                                    text={"Specify an event identifier for sending/receiving messages."}/>
                                <span>Event identifier:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionWebsocketEventIdentifier}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorEventIdentifier"
                                           })}
                                           value={that.state.definitionParameters[i].eventIdentifier}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "eventIdentifier", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorEventSubscription_" + i + "_help"}
                                    title={"Event subscription"}
                                    text={"Specify an event subscription for receiving messages."}/>
                                <span>Event subscription:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionWebsocketEventIdentifier}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorEventSubscription"
                                           })}
                                           value={that.state.definitionParameters[i].eventSubscription}
                                           showClearButton={true}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "eventSubscription", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorEventSource_" + i + "_help"}
                                    title={"Event source"}
                                    text={"Specify an event source for the parameter."}/>
                                <span>Event source:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.definitionParameters[i].eventSource}
                                         placeholder={"appDeliveryScreen"}
                                         onValueChanged={(e) => that.updateValueForParameters({
                                             e, id: that.state.definitionParameters[i].id,
                                             propertyName: "eventSource", item: item, index: i
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup" style={{display: "none"}}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterDataToSend_" + i + "_help"}
                                    title={"JSON data to send"}
                                    text={"Specify a JSON data that will to be sent via websocket."}/>
                                <span>JSON data to send:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].dataToSend}
                                          height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "dataToSend", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup" style={{display: "none"}}>
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterDataShapingForRequest_" + i + "_help"}
                                    title={"Data shaping for request"}
                                    text={"Write javascript code to shape the data before it being sent via a websocket."}/>
                                <span>Data shaping for request:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].dataShapingForRequest}
                                          height={100}
                                          placeholder={"Use the following variables to get started: data."}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "dataShapingForRequest", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterAttachToEvent_" + i + "_help"}
                                    title={"Attach to event"}
                                    text={"Attach this message to a specific event."}/>
                                <span>Attach to event:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionWebsocketAttachToEvent}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorAttachToEvent"
                                           })}
                                           value={that.state.definitionParameters[i].attachToEvent}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "attachToEvent", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterUpdateLocalDataStore_" + i + "_help"}
                                    title={"Update local data source"}
                                    text={"Specify whether to update the local data source."}/>
                                <span>Update local data source:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={LDPH.DropdownSelectionYesNo}
                                           displayExpr={'name'} valueExpr={'id'}
                                           ref={(e) => that.setInputFieldInstance({
                                               e,
                                               input: "parameters_" + that.props.dataViewId +
                                                   "_uiControl_" + i +
                                                   "_parameterEditorUpdateLocalDataStore"
                                           })}
                                           value={that.state.definitionParameters[i].isUpdateLocalDataStore}
                                           onValueChanged={(e) => that.updateValueForParameters({
                                               e, id: that.state.definitionParameters[i].id,
                                               propertyName: "isUpdateLocalDataStore", item: item, index: i
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterWebsocketRequestLogic_" + i + "_help"}
                                    title={"Websocket request logic"}
                                    text={"Specify a websocket request logic for sending data."}/>
                                <span>Websocket request logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].websocketRequestLogic}
                                          height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "websocketRequestLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"parameterEditorParameterWebsocketResponseLogic_" + i + "_help"}
                                    title={"Websocket response logic"}
                                    text={"Specify a websocket response logic for receiving data."}/>
                                <span>Websocket response logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.definitionParameters[i].websocketResponseLogic}
                                          height={100}
                                          onValueChanged={(e) => that.updateValueForParameters({
                                              e, id: that.state.definitionParameters[i].id,
                                              propertyName: "websocketResponseLogic", item: item, index: i
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={60}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.parametersAddOnClick(e)}></i>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardRealtimeMessagingPanel;
