import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import $ from "jquery";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardEditorFieldsPanel from "./LeopardFormEditorFieldsPanel";
import {RequiredRule, Validator} from "devextreme-react/validator";
import {Button as TextBoxButton, TextBox} from "devextreme-react/text-box";

class LeopardFormEditorDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            formDefinition: {}
        };

        this.definitionParameters = [];
        this.uiObjectInstance = [];
    }

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    componentDidMount = () => {
        let definition = this.props.formDefinition;

        if (LDH.IsObjectNull(definition)) {
            definition = {};
        }
        if (!LDH.IsObjectNull(definition.parameters)) {
            this.definitionParameters = definition.parameters;
        }
        this.setState({formDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    updateFormDefinition = (data) => {
        let inputValue = data.e.value;
        let formDef = this.state.formDefinition;
        let clonedFormDef = LDH.DeepClone(formDef);
        clonedFormDef[data.prop] = inputValue;

        this.setState({formDefinition: clonedFormDef}, function () {
            formDef = this.state.formDefinition;
        });
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.formDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.formDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        let show = false;
        if (settingsName === "columnCount") show = true;
        if (settingsName === "enableParentDataDisplay") show = true;
        if (settingsName === "enableGrandParentData") show = true;
        if (settingsName === "widgetInitLogic") show = true;
        if (settingsName === "workspaceDataFromParentLogic") show = true;
        if (settingsName === "showClearButton") show = true;
        if (settingsName === "showPhotosButton") show = true;
        if (settingsName === "showPrefillButton") show = true;
        if (settingsName === "workspaceUnmountLogic") show = true;
        if (settingsName === "prefillPostDataProcessingLogic") show = true;

        if (settingsName === "dataCreateEnableControl") show = true;
        if (settingsName === "dataCreateODataQuery") show = true;
        if (settingsName === "dataCreatePostDataScript") show = true;
        if (settingsName === "dataCreateTemplateDirectory") show = true;
        if (settingsName === "dataCreateTemplateFilename") show = true;
        if (settingsName === "dataCreateSendDataToPersistentId") show = true;
        if (settingsName === "dataCreateSendDataToViewLogic") show = true;
        if (settingsName === "dataCreateCustomDataPostLogic") show = true;
        if (settingsName === "dataCreateClearFormData") show = true;
        if (settingsName === "dataCreateCustomSuccessMessage") show = true;
        if (settingsName === "dataCreateClearChildViewData") show = true;
        if (settingsName === "dataCreatePhotoUploadS3Logic") show = true;

        if (settingsName === "dataUpdateEnableControl") show = true;
        if (settingsName === "dataUpdateHttpRequestType") show = true;
        if (settingsName === "dataUpdateODataQuery") show = true;
        if (settingsName === "dataUpdatePostDataScript") show = true;
        if (settingsName === "dataUpdateTemplateDirectory") show = true;
        if (settingsName === "dataUpdateTemplateFilename") show = true;
        if (settingsName === "dataUpdateSendDataToPersistentId") show = true;
        if (settingsName === "dataUpdateSendDataToViewLogic") show = true;
        if (settingsName === "dataUpdateCustomDataPostLogic") show = true;
        if (settingsName === "dataUpdateClearFormData") show = true;
        if (settingsName === "dataUpdateCustomSuccessMessage") show = true;
        if (settingsName === "dataUpdateClearChildViewData") show = true;
        if (settingsName === "dataUpdatePhotoUploadS3Logic") show = true;

        if (settingsName === "dataCancelEnableControl") show = true;
        if (settingsName === "dataCancelHttpRequestType") show = true;
        if (settingsName === "dataCancelODataQuery") show = true;
        if (settingsName === "dataCancelPostDataScript") show = true;
        if (settingsName === "dataCancelTemplateDirectory") show = true;
        if (settingsName === "dataCancelTemplateFilename") show = true;
        if (settingsName === "dataCancelSendDataToPersistentId") show = true;
        if (settingsName === "dataCancelSendDataToViewLogic") show = true;
        if (settingsName === "dataCancelCustomDataPostLogic") show = true;
        if (settingsName === "dataCancelClearFormData") show = true;
        if (settingsName === "dataCancelCustomSuccessMessage") show = true;
        if (settingsName === "dataCancelClearChildViewData") show = true;
        if (settingsName === "dataCancelPhotoUploadS3Logic") show = true;

        if (settingsName === "dataFinaliseEnableControl") show = true;
        if (settingsName === "dataFinaliseHttpRequestType") show = true;
        if (settingsName === "dataFinaliseODataQuery") show = true;
        if (settingsName === "dataFinalisePostDataScript") show = true;
        if (settingsName === "dataFinaliseTemplateDirectory") show = true;
        if (settingsName === "dataFinaliseTemplateFilename") show = true;
        if (settingsName === "dataFinaliseSendDataToPersistentId") show = true;
        if (settingsName === "dataFinaliseSendDataToViewLogic") show = true;
        if (settingsName === "dataFinaliseCustomDataPostLogic") show = true;
        if (settingsName === "dataFinaliseClearFormData") show = true;
        if (settingsName === "dataFinaliseCustomSuccessMessage") show = true;
        if (settingsName === "dataFinaliseClearChildViewData") show = true;
        if (settingsName === "dataFinalisePhotoUploadS3Logic") show = true;

        if (settingsName === "apiEnabled") show = true;
        if (settingsName === "apiUseEventSync") show = true;
        if (settingsName === "apiODataQuery") show = true;
        if (settingsName === "apiODataHttpPostBody") show = true;
        if (settingsName === "apiPostDataScript") show = true;

        // Not required for now.
        if (settingsName === "dataCreateHttpRequestType") show = false;
        if (settingsName === "dataUpdateHttpRequestType") show = false;
        return show;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================

    saveDataButtonOnClick = () => {
        let that = this;
        let definition = LDH.DeepClone(that.state.formDefinition);
        definition.parameters = that.definitionParameters;

        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configureformeditor").css("visibility", "visible");

        that.uiObjectInstance["designer_save_button"].option("disabled", true);
        that.uiObjectInstance["designer_close_button"].option("disabled", true);

        setTimeout(function () {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateDataViewDefinitionByType(userId, organizationId,
                dataViewId, definition, function () {
                    let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                    $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LRH.ShowToast("Your form configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated",
                            "Your current configuration settings cannot be saved due to a newer " +
                            "version found on the server, please refresh your browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configureformeditor").css("visibility", "hidden");

                    that.uiObjectInstance["designer_save_button"].option("disabled", false);
                    that.uiObjectInstance["designer_close_button"].option("disabled", false);
                }, "form-editor");
        }, 100);
    };

    cancelButtonOnClick = (e) => {
        this.props.formDesignerButtonOnClick(e);
    };

    initializeTabItemTitle = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    initializeSettingsNumberBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateFormDefinition({
                                               e, tabInfo: data, prop: data.settingsName
                                           })}>
                                </NumberBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsSelectBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateFormDefinition({
                                               e, tabInfo: data, prop: data.settingsName
                                           })}>
                                </SelectBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextAreaWithButtonField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        let height = LDH.IsObjectNull(data.height) ? "140px" : data.height;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={height}
                                          onValueChanged={(e) => this.updateFormDefinition({
                                              e, tabInfo: data, prop: data.settingsName
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule
                                                    validationCallback={(e) => this.customValidationRuleCallback({
                                                        e,
                                                        input: data.settingsName,
                                                        rules: [{rule: "safeinput"}]
                                                    })} type="custom"/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton location={'after'} options={{
                                                icon: data.buttonIcon, type: 'default',
                                                onClick: () => {
                                                    data.onClick();
                                                }
                                            }} name={data.settingsName + "_MiniButton"}>
                                            </TextBoxButton>
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    initializeSettingsTextBoxField = (data) => {
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                            </div>
                            <div>
                                <TextBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e, input: data.settingsName})}
                                         onValueChanged={(e) => this.updateFormDefinition({
                                             e, tabInfo: data, prop: data.settingsName
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule validationCallback={(e) => this.customValidationRuleCallback({
                                            e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                        })} type="custom">
                                        </RequiredRule>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    definitionParametersOnChanged = (data) => {
        this.definitionParameters = data.definitionParameters;
    };

    tabItemContent = (data) => {
        if (data.tabId === 0) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">General</div>
                                    {
                                        this.initializeSettingsNumberBoxField({
                                            settingsName: "columnCount",
                                            defaultValue: 4,
                                            displayName: "Column count:",
                                            minValue: 1,
                                            maxValue: 30
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "enableParentDataDisplay",
                                            defaultValue: true,
                                            displayName: "Show values from selected parent:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "enableGrandParentData",
                                            defaultValue: false,
                                            displayName: "Use grand parent view as ParentData:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showClearButton",
                                            defaultValue: false,
                                            displayName: "Show clear data button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showPhotosButton",
                                            defaultValue: false,
                                            displayName: "Show photos button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "showPrefillButton",
                                            defaultValue: false,
                                            displayName: "Show prefill button:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "widgetInitLogic",
                                            defaultValue: undefined,
                                            displayName: "Widget initialisation logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "workspaceDataFromParentLogic",
                                            defaultValue: undefined,
                                            displayName: "Data from parent logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "workspaceUnmountLogic",
                                            defaultValue: undefined,
                                            displayName: "Workspace unmount logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "prefillPostDataProcessingLogic",
                                            defaultValue: undefined,
                                            displayName: "Prefill post data processing logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Data Create</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCreateEnableControl",
                                            defaultValue: true,
                                            displayName: "Show control:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCreateHttpRequestType",
                                            defaultValue: "post",
                                            displayName: "HTTP request method:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHttpRequestTypeWithWebsocketHybrid
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCreateTemplateDirectory",
                                            defaultValue: "",
                                            displayName: "Template directory:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCreateTemplateFilename",
                                            defaultValue: "",
                                            displayName: "Template filename:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCreateODataQuery",
                                            defaultValue: undefined,
                                            displayName: "OData query:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px",
                                            placeholder: "/transaction?$filter=Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCreatePostDataScript",
                                            defaultValue: undefined,
                                            displayName: "Data shaping logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCreateSendDataToPersistentId",
                                            defaultValue: "",
                                            displayName: "Send data to view by Persistent ID:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCreateSendDataToViewLogic",
                                            defaultValue: undefined,
                                            displayName: "Send data to view logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCreateCustomDataPostLogic",
                                            defaultValue: undefined,
                                            displayName: "Custom data post logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCreatePhotoUploadS3Logic",
                                            defaultValue: undefined,
                                            displayName: "Upload photos to S3 logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCreateClearFormData",
                                            defaultValue: true,
                                            displayName: "Clear form data after created:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCreateClearChildViewData",
                                            defaultValue: true,
                                            displayName: "Clear childview data by Persistent ID:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCreateCustomSuccessMessage",
                                            defaultValue: "",
                                            displayName: "Custom success message:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Data Update</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataUpdateEnableControl",
                                            defaultValue: true,
                                            displayName: "Show control:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataUpdateHttpRequestType",
                                            defaultValue: "post",
                                            displayName: "HTTP request method:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHttpRequestTypeWithWebsocketHybrid
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataUpdateTemplateDirectory",
                                            defaultValue: "",
                                            displayName: "Template directory:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataUpdateTemplateFilename",
                                            defaultValue: "",
                                            displayName: "Template filename:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataUpdateODataQuery",
                                            defaultValue: undefined,
                                            displayName: "OData query:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px",
                                            placeholder: "/transaction?$filter=Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataUpdatePostDataScript",
                                            defaultValue: undefined,
                                            displayName: "Data shaping logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataUpdateSendDataToPersistentId",
                                            defaultValue: "",
                                            displayName: "Send data to view by Persistent ID:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataUpdateSendDataToViewLogic",
                                            defaultValue: undefined,
                                            displayName: "Send data to view logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataUpdateCustomDataPostLogic",
                                            defaultValue: undefined,
                                            displayName: "Custom data post logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataUpdatePhotoUploadS3Logic",
                                            defaultValue: undefined,
                                            displayName: "Upload photos to S3 logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataUpdateClearFormData",
                                            defaultValue: true,
                                            displayName: "Clear form data after updated:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataUpdatedClearChildViewData",
                                            defaultValue: true,
                                            displayName: "Clear childview data by Persistent ID:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataUpdateCustomSuccessMessage",
                                            defaultValue: "",
                                            displayName: "Custom success message:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Data Cancel</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCancelEnableControl",
                                            defaultValue: true,
                                            displayName: "Show control:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCancelHttpRequestType",
                                            defaultValue: "post",
                                            displayName: "HTTP request method:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHttpRequestTypeWithWebsocketHybrid
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCancelTemplateDirectory",
                                            defaultValue: "",
                                            displayName: "Template directory:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCancelTemplateFilename",
                                            defaultValue: "",
                                            displayName: "Template filename:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCancelODataQuery",
                                            defaultValue: undefined,
                                            displayName: "OData query:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px",
                                            placeholder: "/transaction?$filter=Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCancelPostDataScript",
                                            defaultValue: undefined,
                                            displayName: "Data shaping logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCancelSendDataToPersistentId",
                                            defaultValue: "",
                                            displayName: "Send data to view by Persistent ID:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCancelSendDataToViewLogic",
                                            defaultValue: undefined,
                                            displayName: "Send data to view logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCancelCustomDataPostLogic",
                                            defaultValue: undefined,
                                            displayName: "Custom data post logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataCancelPhotoUploadS3Logic",
                                            defaultValue: undefined,
                                            displayName: "Upload photos to S3 logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCancelClearFormData",
                                            defaultValue: true,
                                            displayName: "Clear form data after created:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataCancelClearChildViewData",
                                            defaultValue: true,
                                            displayName: "Clear childview data by Persistent ID:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataCancelCustomSuccessMessage",
                                            defaultValue: "",
                                            displayName: "Custom success message:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Data Finalise</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataFinaliseEnableControl",
                                            defaultValue: true,
                                            displayName: "Show control:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataFinaliseHttpRequestType",
                                            defaultValue: "post",
                                            displayName: "HTTP request method:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionHttpRequestTypeWithWebsocketHybrid
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataFinaliseTemplateDirectory",
                                            defaultValue: "",
                                            displayName: "Template directory:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataFinaliseTemplateFilename",
                                            defaultValue: "",
                                            displayName: "Template filename:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataFinaliseODataQuery",
                                            defaultValue: undefined,
                                            displayName: "OData query:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px",
                                            placeholder: "/transaction?$filter=Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataFinalisePostDataScript",
                                            defaultValue: undefined,
                                            displayName: "Data shaping logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataFinaliseSendDataToPersistentId",
                                            defaultValue: "",
                                            displayName: "Send data to view by Persistent ID:"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataFinaliseSendDataToViewLogic",
                                            defaultValue: undefined,
                                            displayName: "Send data to view logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataFinaliseCustomDataPostLogic",
                                            defaultValue: undefined,
                                            displayName: "Custom data post logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "dataFinalisePhotoUploadS3Logic",
                                            defaultValue: undefined,
                                            displayName: "Upload photos to S3 logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataFinaliseClearFormData",
                                            defaultValue: true,
                                            displayName: "Clear form data after created:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "dataFinaliseClearChildViewData",
                                            defaultValue: true,
                                            displayName: "Clear childview data by Persistent ID:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextBoxField({
                                            settingsName: "dataFinaliseCustomSuccessMessage",
                                            defaultValue: "",
                                            displayName: "Custom success message:"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">API Endpoint</div>
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "apiEnabled",
                                            defaultValue: false,
                                            displayName: "Retrieve data via API endpoint:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsSelectBoxField({
                                            settingsName: "apiUseEventSync",
                                            defaultValue: false,
                                            displayName: "Use EventSync method:",
                                            dataSource: LeopardDropdownHelper.DropdownSelectionYesNo
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "apiODataQuery",
                                            defaultValue: undefined,
                                            displayName: "OData query:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px",
                                            placeholder: "/transaction?$filter=Id eq {Id} or Barcode eq '{Barcode}'"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "apiODataHttpPostBody",
                                            defaultValue: undefined,
                                            displayName: "Data to post (JSON):",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "202px"
                                        })
                                    }
                                    {
                                        this.initializeSettingsTextAreaWithButtonField({
                                            settingsName: "apiPostDataScript",
                                            defaultValue: undefined,
                                            displayName: "Data shaping logic:",
                                            hasMiniButton: false,
                                            validator: false,
                                            height: "325px"
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto", maxHeight: "432px"}} className={"leopard-dataview-configbox"}>
                        <LeopardEditorFieldsPanel
                            dataViewId={this.props.dataViewId}
                            definitionParameters={this.definitionParameters}
                            definitionParametersOnChanged={(e) => this.definitionParametersOnChanged(e)}
                        />
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        return (
            <React.Fragment>
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div className={"leopard-editgridview-title"}>Configure Form</div>
                    <div className={"leopard-gray-panel-container"} style={{marginTop: "10px"}}>
                        <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionFormTabPanel}
                                  onOptionChanged={this.onSelectionChanged}
                                  loop={true} itemTitleRender={this.initializeTabItemTitle}
                                  itemComponent={this.tabItemContent} animationEnabled={true} swipeEnabled={false}
                        ></TabPanel>
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                        <span style={{padding: "0 10px 0 0", display: "none"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                    ref={(e) => this.setInputFieldInstance({e, input: "buttonPreview"})}
                                    onClick={() => this.previewButtonOnClick(function () {
                                    })}>
                            </Button>
                        </span>
                    <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_save_button"})}
                                    onClick={(e) => this.saveDataButtonOnClick(e)}>
                            </Button>
                        </span>
                    <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "designer_close_button"})}
                                    onClick={(e) => this.cancelButtonOnClick({e})}>
                            </Button>
                        </span>
                    <span className={"loading-progress-configureformeditor"}>
                            <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                        </span>
                </div>
                <br/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardFormEditorDesigner);
